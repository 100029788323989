import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { navigate } from 'gatsby'

import {
  FormGroup,
  Paragraph,
  H1,
  H2,
  Flex,
  Span,
} from '@allied-solutions/affinity'

import { useFetchBorrower } from '../../../hooks'
import {
  AppLayout,
  BackButton,
  ButtonGroup,
  Hint,
  InfoBox,
  NextButton,
  SubmitInsuranceLayout,
} from '../../../components'
const LookupMortgagePage = ({ location }) => {
  const { handleSubmit: fetchBorrower } = useFetchBorrower(
    '/no-reference-id/confirm-identity/'
  )

  const handleSubmit = async (values, bag) => {

    return (

      fetch(`${process.env.GATSBY_API_URL}/reference-ids/mortgage?LoanNumber=${values.loan_number}&Address1StartsWith=${values.firstpart_street}&LastName=${values.last_name}&PostalCodeStartsWith=${values.postal_code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true'
        }
      })
        // handle HTTP status codes
        .then(res => {
          if (res.ok) return res.json()

          if (res.status === 404) throw new Error('Not found.')
        })

        // success
        .then(data => {
          fetchBorrower({ refId: data.ReferenceIds[0] }, bag)
        })

        // error
        .catch(error => {
          //console.log(error)
          navigate('/submit-insurance/no-reference-id/upload-documents/')
        })
    )
  }

  return (
    <SubmitInsuranceLayout location={location}>
      <Formik
        initialValues={{
          firstpart_street: '',
          last_name: '',
          loan_number: '',
          postal_code: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          firstpart_street: Yup.string().required(
            'Please fill out this field.'
          ),
          last_name: Yup.string().required('Please fill out this field.'),
          loan_number: Yup.string().required('Please fill out this field.'),
          postal_code: Yup.string().required('Please fill out this field.'),
        })}
      >
        {props => (
          <Form noValidate>
            <AppLayout
              leftSide={
                <>
                  <Paragraph
                    mb={6}
                    typeStyle="labelLarge"
                    textAlign="left"
                    color="gray600"
                  >
                    Look up my account
                  </Paragraph>
                  <H1 className="h6" typeStyle="h6" fontSize="28px">
                    You chose to look up a loan on a home.
                  </H1>
                  <Paragraph
                    typeStyle="bodyMedium"
                    textAlign="left"
                    color="gray600"
                  >
                    Check your loan documents and fill out this information.
                    Fill out the information as it appears on your loan.
                  </Paragraph>
                </>
              }
              rightSide={
                <InfoBox>
                  <H2
                    className="titleSmall"
                    typeStyle="titleSmall"
                    fontWeight={900}
                    textAlign="left"
                  >
                    Loan lookup for home
                  </H2>

                  <Flex>
                    <Flex.Col colWidth={[12, 6, 12, 12, 6]}>
                      <FormGroup id="last_name" mt={8} required focusStyleTest>
                        <FormGroup.Label visible>
                          Last Name
                          <Span fontWeight="bold" ml={1} color="red1000">
                            *
                          </Span>
                        </FormGroup.Label>
                        <FormGroup.InputGroup>
                          <FormGroup.InputGroup.Input />
                        </FormGroup.InputGroup>
                        <FormGroup.Caption />
                      </FormGroup>
                    </Flex.Col>
                    <Flex.Col colWidth={[12, 6, 12, 12, 6]}>
                      <FormGroup
                        id="postal_code"
                        mt={8}
                        required
                        focusStyleTest
                      >
                        <FormGroup.Label visible>
                          Zip Code
                          <Span fontWeight="bold" ml={1} color="red1000">
                            *
                          </Span>
                        </FormGroup.Label>
                        <FormGroup.InputGroup>
                          <FormGroup.InputGroup.Input />
                        </FormGroup.InputGroup>
                        <FormGroup.Caption />
                      </FormGroup>
                    </Flex.Col>
                    <Flex.Col colWidth={[null]}>
                      <FormGroup
                        id="firstpart_street"
                        mt={8}
                        required
                        focusStyleTest
                      >
                        <FormGroup.Label
                          visible
                          hint={
                            <Hint
                              id="Tooltip--whatsThis"
                              content="We only need the number of your address to look up your account. (Ex: for 456 Main St, only enter 456)"
                            />
                          }
                        >
                          Street Number (number only)
                          <Span fontWeight="bold" ml={1} color="red1000">
                            *
                          </Span>
                        </FormGroup.Label>
                        <FormGroup.InputGroup>
                          <FormGroup.InputGroup.Input />
                        </FormGroup.InputGroup>
                        <FormGroup.Caption />
                      </FormGroup>
                    </Flex.Col>
                    <Flex.Col>
                      <FormGroup
                        id="loan_number"
                        mt={8}
                        required
                        focusStyleTest
                      >
                        <FormGroup.Label
                          visible
                          hint={
                            <Hint
                              id="Tooltip--whatsThis-Loan-Number"
                              content="A loan number uniquely identifies your loan vs. someone else’s loan. It’s usually found on a monthly statement from your lender."
                            />
                          }
                        >
                          Loan Number
                          <Span fontWeight="bold" ml={1} color="red1000">
                            *
                          </Span>
                        </FormGroup.Label>
                        <FormGroup.InputGroup>
                          <FormGroup.InputGroup.Input />
                        </FormGroup.InputGroup>
                        <FormGroup.Caption />
                      </FormGroup>
                    </Flex.Col>
                  </Flex>
                </InfoBox>
              }
              footerButtons={
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <BackButton id="NoRefId__Lookup__Button--back" />
                  <NextButton
                    formikProps={props}
                    id="NoRefId__Lookup__Button--next"
                  />
                </ButtonGroup>
              }
            />
          </Form>
        )}
      </Formik>
    </SubmitInsuranceLayout>
  )
}

export default LookupMortgagePage
